<template>
	<div class="auth-wrapper auth-v1 px-2">
		<div class="auth-inner py-2">
			<b-card class="mb-0">
				<div class="brand-logo align-items-center">
					<b-img :src="require('@/assets/images/logo/logo.png')" alt="logo" width="54" />
					<h2 class="brand-text text-primary ml-50 mb-0">
						PWP
					</h2>
				</div>

				<b-card-title class="mb-1 text-center">
					Welcome back
				</b-card-title>
				<b-card-text class="mb-2 text-center">
					Please sign-in to your account and start working
				</b-card-text>

				<validation-observer ref="loginForm" #default="{ invalid }">
					<b-form class="auth-login-form mt-2" @submit.prevent="login">
						<b-form-group label-for="phone" label="Phone">
							<validation-provider #default="{ errors }" name="Phone" rules="required">
								<b-form-input
									id="phone"
									v-model="userPhone"
									name="login-phone"
									:state="errors.length > 0 ? false : null"
									placeholder="777 777 777"
									autofocus
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>

						<b-form-group>
							<div class="d-flex justify-content-between">
								<label for="password">Password</label>
							</div>
							<validation-provider #default="{ errors }" name="Password" rules="required">
								<b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
									<b-form-input
										id="password"
										v-model="password"
										:type="passwordFieldType"
										class="form-control-merge"
										:state="errors.length > 0 ? false : null"
										name="login-password"
										placeholder="Password"
									/>

									<b-input-group-append is-text>
										<feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
									</b-input-group-append>
								</b-input-group>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>

						<b-form-group>
							<b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">
								Remember Me
							</b-form-checkbox>
						</b-form-group>

						<b-button variant="primary" type="submit" block :disabled="invalid">
							Sign in
						</b-button>
					</b-form>
				</validation-observer>
			</b-card>
		</div>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
	BButton,
	BForm,
	BImg,
	BFormInput,
	BFormGroup,
	BCard,
	BLink,
	BCardTitle,
	BCardText,
	BInputGroup,
	BInputGroupAppend,
	BFormCheckbox,
} from 'bootstrap-vue';
import { required } from '@validations';
import store from '@/store';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';

export default {
	components: {
		BButton,
		BForm,
		BImg,
		BFormInput,
		BFormGroup,
		BCard,
		BCardTitle,
		BCardText,
		BInputGroup,
		BInputGroupAppend,
		BFormCheckbox,
		ValidationProvider,
		ValidationObserver,
	},

	mixins: [togglePasswordVisibility],

	data() {
		return {
			userPhone: '',
			password: '',
			status: '',
			required,
		};
	},
	computed: {
		passwordToggleIcon() {
			return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
		},
	},

	methods: {
		async login() {
			await store.dispatch('auth/login', {
				phone: this.userPhone,
				password: this.password,
				token_name: 'web',
			});

			store.dispatch('notifications/init');
			this.$router.push(this.$route.query.redirectFrom || { name: 'home' });
		},
	},
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
